// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-author-page-js": () => import("/opt/build/repo/src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-posts-js": () => import("/opt/build/repo/src/pages/AllPosts.js" /* webpackChunkName: "component---src-pages-all-posts-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/Blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chi-siamo-js": () => import("/opt/build/repo/src/pages/ChiSiamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("/opt/build/repo/src/pages/Contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-disinfezione-js": () => import("/opt/build/repo/src/pages/Disinfezione.js" /* webpackChunkName: "component---src-pages-disinfezione-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("/opt/build/repo/src/pages/Intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-sent-message-success-page-js": () => import("/opt/build/repo/src/pages/sent-message-success-page.js" /* webpackChunkName: "component---src-pages-sent-message-success-page-js" */),
  "component---src-pages-servizi-js": () => import("/opt/build/repo/src/pages/Servizi.js" /* webpackChunkName: "component---src-pages-servizi-js" */)
}

